<template src="./Template.vue"></template>

<script>
import ReportingTemplateMixin from '@/mixins/ReportingTemplateMixin'

export default {
  name: 'app-report',
  props: ['slug', 'level', 'type', 'color'],
  mixins: [ReportingTemplateMixin],
  mounted() {
    let payload = {}
    this.title = this.$route.meta.routeName
    // console.log(this.$route.params)

    // http://localhost:8002/reporting/pdf-preview/34/DIV01/heatmap
    let endpoint = this.endpoint + '/pdfPreview/' + this.slug + '/' + this.level + '/' + this.type + '/' + this.color;

    // console.log('pdf-preview created: ', this.slug, endpoint )
    payload = {
      endpoint: endpoint,
    }
    this.fetchItem(payload)

  }
}
</script>